<template>
    <div>
        <vx-card class="pb-10">
            <div class="vx-row">
                <!-- titulo -->
                <div class="vx-col w-1/2">
                    <h2 class="text-info">{{ this.$t('Carga tus categorías') }}</h2>
                </div>

                <!-- boton descarga -->
                <div class="vx-col w-1/2">
                    <button @click="downloadFileFormat()" class="vs-component vs-button rounded-full vs-button-info vs-button-filled small float-right">
                        <img :src="require(`@/assets/icons/DownloadFileIcon.svg`)" width="20px" class="inline-block align-middle mr-2"/>
                        {{ this.$t('Descargar formato de Carga Masiva') }}
                    </button>
                </div>

                <div class="vx-col w-full">
                    <div class="vx-row">
                        <!-- carga de archivos -->
                        <div class="vx-col lg:w-1/2 sm:w-full mt-10">
                            <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" ref="file" accept=".xlsx" @change="onChange()" />

                            <div
                                class="border-2 border-dashed text-center pt-24 pb-24 mt-5 cursor-pointer rounded-lg"
                                @click="clickFileButton()"
                                @dragover="dragover"
                                @dragleave="dragleave"
                                @drop="drop"
                            >
                                <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="50px" class="inline-block align-middle"/><br/><br/>
                                <p>{{ this.$t('Carga tu archivo') }}</p>
                                <span>{{ this.$t('Archivos Soportados:') }}</span><br/>
                                <span>.xlsx</span><br/><br/>
                                <vs-button color="#daf7fd" text-color="#22bad6" class="rounded-full">
                                    {{ this.$t('Cargar Archivo') }}
                                </vs-button>
                            </div>
                        </div>

                        <!-- lectura de archivos -->
                        <div class="vx-col lg:w-1/2 sm:w-full mt-10">
                    
                            <!-- lista de archivos -->
                            <VuePerfectScrollbar style="height: 85%">
                                <h4>{{ this.$t('Archivos subidos') }}</h4>

                                <vx-card v-if="files.length === 0" class="text-center mt-5 pt-2 pb-2">
                                    <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="40px" class="inline-block align-middle mr-5"/>
                                    <span class="align-middle text-thin">
                                        {{ this.$t('No se encontraron archivos subidos') }}
                                    </span>   
                                </vx-card>

                                <vx-card v-else class="mt-5" v-for="(file, i) in files" v-bind:key="i">
                                    <div class="vx-row">
                                        <div class="vx-col w-auto">
                                            <img :src="require(`@/assets/icons/ExcelIcon.svg`)" width="64px" />
                                        </div>

                                        <div class="vx-col w-5/6">
                                            <vs-button color="transparent" text-color="#000" size="small" class="float-right" @click="deleteFile(i)">
                                                <feather-icon icon="XIcon" />
                                            </vs-button>

                                            <h3>{{ file.file.name }}</h3>
                                            <span>{{ this.$t('Subido') }} {{ file.progress }}%</span>
                                            <vs-progress :height="12" :percent="file.progress" :color="file.error ? 'danger' : 'success'"></vs-progress>
                                            <!-- <p v-if="file.error" class="text-danger"> {{ file.error }} </p> -->
                                        </div>
                                    </div>
                                </vx-card>
                            </VuePerfectScrollbar>

                            <!-- boton guardar -->
                            <div class="w-full mt-5">
                                <button class="vs-component vs-button rounded-full vs-button-primary vs-button-filled float-right" :disabled="files.length === 0" @click="uploadFiles()">
                                    {{ this.$t('Cargar Archivos') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default Vue.extend({
    data() {
        return {
            files: [],
        }
    },

    watch: {
        files: {
            handler( val ) {
                const uploaded = val.filter( f => f.progress === 100 );
                const fileErrs = val.filter( fe => fe.error );
                if( uploaded.length === this.files.length && fileErrs.length === 0 ) this.$router.push( { path : '/app/products' } );
            },
            deep: true,
        }
    },

    methods: {
        getFileFormat( filename ) {
            return filename.substr(filename.lastIndexOf('.') + 1);
        },

        onChange() {
            const filename = this.$refs.file.files[0].name;
            const ext = this.getFileFormat(filename);
            
            if( ext !== 'xlsx' ) {                
                return this.$vs.notify({
                    title: this.$t('Formato Incorrecto'),
                    text: this.$t('Cargue archivos .xlsx solamente'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
            }

            const fileMapped = [...this.$refs.file.files].map( file => {
                let ob = Object.assign({}, file);
                ob.file = file;
                ob.progress = 0;
                ob.error = '';
                return ob;
            });

            fileMapped.forEach( file => {
                this.files.push(file);
            });           
            
        },

        clickFileButton() {
            this.$refs.file.click();
        },

        dragover( ev ) {            
            ev.preventDefault();
            
            if (!ev.currentTarget.classList.contains('bg-primary')) {
                ev.currentTarget.classList.remove('bg-gray-100');
                ev.currentTarget.classList.add('bg-primary');
            }
        },

        dragleave( ev ) {
            ev.currentTarget.classList.remove('bg-primary');
        },

        drop( ev ) {
            ev.preventDefault();
            this.$refs.file.files = ev.dataTransfer.files;
            this.onChange();
            ev.currentTarget.classList.remove('bg-primary');
        },

        deleteFile( index ) {
            this.files.splice( index, 1 );
        },

        uploadFiles() {
            this.$store.dispatch( "categories/uploadMassiveCategories", this.files )
                .then(() => {
                    this.$vs.notify({
                        title: this.$t('Se ha cargado el archivo'),
                        text: this.$t('Verifica su análisis en el menú de notificaciones'),
                        iconPack: 'feather',
                        icon: 'icon-check',
                        color: 'primary',
                        time: 7000,
                    });
                });
        },

        downloadFileFormat() {
            this.$vs.loading();
            this.$store.dispatch( 'getFileUrl', '/documents/example-formats/categories.xlsx' )
            .then( url => {
                this.$vs.loading.close();
                if( url ) {
                    const link = document.createElement('a');
                    
                    link.setAttribute( 'download', 'true' );
                    link.href = url;
                    link.click();
                    link.remove();
                }
            });
        },
    },

    components : {
        VuePerfectScrollbar,
    }
});
</script>
